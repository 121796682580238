<div class="card">
    <div class="card-header pt-2">
        <h5 class="text-center text-uppercase pdt5"><i class="fa fa-clock" aria-hidden="true" title="Admin"></i>
            Business Hours
            <button *ngIf="businessHours.length == 0" type="button"
                class="btn btn-green btn-sm add-agent-btn pull-right me-2"
                (click)="isEdit=false;holidayList=[];addBusinessHoursModal.show();"><i *ngIf="!isAdding"
                    class="fa fa-plus"></i>&nbsp;Add
                New</button>
        </h5>
        <hr>
    </div>
    <div class="card-body">
        <div class="content table-responsive table-full-width scroll-table">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Timezone</th>
                        <th>Business Hours</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let hours of businessHours; let i=index">
                        <td>{{hours.name}}</td>
                        <td>{{hours.description}}</td>
                        <td>{{hours.timezone}}</td>
                        <td>{{hours.business_hours == 'hours' ? 'Custom' : hours.business_hours}}</td>
                        <td>
                            <a (click)="isEdit=true;selectedBH=hours;clearBHForm(hours);addBusinessHoursModal.show()"><i
                                    class="fa fa-pencil-square-o text-primary" aria-hidden="true"></i></a>

                            <a (click)="selectedBH=hours; deleteBusinessHoursModal.show();"><i
                                    class="fa fa-trash-o text-danger" aria-hidden="true"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<div bsModal #addBusinessHoursModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade fbmsg" tabindex="-1"
    role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="businessHoursForm" (ngSubmit)="addHours(businessHoursForm);" ngNativeValidate>
                <div class="modal-header">
                    <div class="header">
                        <h4 class="title mg0"><span *ngIf="!isEdit">Add</span><span *ngIf="isEdit">Edit</span>
                            Business Hours</h4>
                        <button type="button" class="close"
                            (click)="addBusinessHoursModal.hide();isEdit=false;clearBHForm();">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6 row">
                            <div class="col-md-12 form-group">
                                <label>Name</label>
                                <input class="form-control" placeholder="Name" type="text" formControlName="name">
                                <div
                                    *ngIf="businessHoursForm.get('name').invalid && businessHoursForm.get('name').touched">
                                    <small class="text-danger">Name is required</small>
                                </div>
                            </div>
                            <div class="col-md-12 form-group">
                                <label>Timezone</label>
                                <select class="form-control" formControlName="timezone">
                                    <option *ngFor="let item of timezones" [value]="item">
                                        {{item}}</option>
                                </select>
                                <div
                                    *ngIf="businessHoursForm.get('timezone').invalid && businessHoursForm.get('timezone').touched">
                                    <small class="text-danger">Timezone is required</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Description</label>
                                <textarea class="form-control" placeholder="Description" type="text"
                                    formControlName="description" rows="5"></textarea>
                                <div
                                    *ngIf="businessHoursForm.get('description').invalid && businessHoursForm.get('description').touched">
                                    <small class="text-danger">Description is required</small>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Set Business Hours</label>
                                <div class="filters d-flex justify-content-center">
                                    <div class="radio-check">
                                        <input id="progress" formControlName="business_hours" type="radio"
                                            name="agent-dept" [value]="'24x7'" (change)="applyConditionalValidators()">
                                        <label for="progress">24hours x 7days</label>
                                        <input id="open" formControlName="business_hours" type="radio" name="agent-dept"
                                            [value]="'hours'" (change)="applyConditionalValidators()">
                                        <label for="open">Set Hours</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="businessHoursForm.get('business_hours').value == 'hours'">
                            <div formGroupName="hours_list" class="px-1 hours_list">
                                <div *ngFor="let day of ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']; let i = index"
                                    [formGroupName]="day" class="hour-days my-2">
                                    <div class="w-50">
                                        <label class="cpr">
                                            <input type="checkbox" formControlName="active"
                                                (change)="onCheckboxChange(day)">
                                            {{ day }}
                                        </label>
                                    </div>
                                    <div *ngIf="businessHoursForm.get(['hours_list', day, 'active']).value"
                                        class="d-flex w-50">
                                        <div class="d-flex me-1 align-items-center">
                                            <label class="me-1">From&nbsp;:&nbsp;</label>
                                            <div>
                                                <select class="form-control" formControlName="from">
                                                    <option hidden disabled [value]="null">HH:MM</option>
                                                    <option *ngFor="let time of timeList" [value]="time">
                                                        {{time}}</option>
                                                </select>
                                                <div
                                                    *ngIf="businessHoursForm.get(['hours_list', day, 'from']).invalid && businessHoursForm.get(['hours_list', day, 'from']).touched">
                                                    <small class="text-danger">Required</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center me-5">
                                            <label class="me-1">To&nbsp;:&nbsp;</label>
                                            <div>
                                                <select class="form-control" formControlName="to">
                                                    <option hidden disabled [value]="null">HH:MM</option>
                                                    <option *ngFor="let time of timeList" [value]="time">
                                                        {{time}}</option>
                                                </select>
                                                <div
                                                    *ngIf="businessHoursForm.get(['hours_list', day, 'to']).invalid && businessHoursForm.get(['hours_list', day, 'to']).touched">
                                                    <small class="text-danger">Required</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label>Set Holidays</label>
                            <div class="row holiday-box">
                                <div class="col-md-6 row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Holiday Name</label>
                                            <input class="form-control" placeholder="Name" type="text"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="holiday.name">
                                            <div
                                                *ngIf="businessHoursForm.get('name').invalid && businessHoursForm.get('name').touched">
                                                <small class="text-danger">Name is required</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group mauto">
                                            <label>Holiday Date</label>
                                            <input type="text" class="form-control" ngxDaterangepickerMd
                                                [alwaysShowCalendars]="true" [singleDatePicker]="true"
                                                [autoApply]="true" [showClearButton]="true"
                                                [locale]="{format: 'DD-MM-YYYY'}" placeholder="Select Date"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="holiday.date" />
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-right">
                                        <button type="button" class="btn btn-gradient" (click)="addHoliday()">Add
                                            Holiday</button>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Holidays Added</label>
                                    <ul class="holidays-preview" [class.empty]="!holidayList.length">
                                        <li class="no-holiday" *ngIf="!holidayList.length">Holidays not added yet!</li>
                                        <li *ngFor="let item of holidayList; let i = index;">{{item.name}} -
                                            {{item.date}}
                                            <a class="pull-right text-danger" (click)="holidayList.splice(i,1)"><i
                                                    class="fa fa-times"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn"
                        (click)="addBusinessHoursModal.hide();isEdit=false;clearBHForm();"><i class="fa fa-times"
                            aria-hidden="true"></i>Cancel</button>
                    <button type="submit" class="btn btn-gradient">
                        <i *ngIf="isAdding" class="fa fa-spinner fa-spin fa-pulse"></i>
                        <i *ngIf="!isAdding" class="fa fa-check"></i>
                        <span *ngIf="!isEdit">Save</span><span *ngIf="isEdit">Edit</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>


<div bsModal #deleteBusinessHoursModal="bs-modal" [config]="{backdrop: 'static'}" class="modal fade fbmsg" tabindex="-1"
    role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <div class="header">
                    <h5 class="title mg0 fw-bold">Delete Business Hour</h5>
                    <button type="button" class="close" (click)="deleteBusinessHoursModal.hide();">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div class="modal-body">
                <p class="text-center">Are you sure to delete business hour <b>{{selectedBH?.name}}</b> ? </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" (click)="deleteBusinessHoursModal.hide();"><i class="fa fa-times"
                        aria-hidden="true"></i>Cancel</button>
                <button type="button" class="btn btn-danger" (click)="deleteBH(selectedBH?.id)"> Delete </button>
            </div>
        </div>
    </div>
</div>